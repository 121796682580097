import { useState } from 'react'

// this T lets us set type in initialization of useSessionStorage in component
function useSessionStorage<T>(
    key: string,
    initialValue: T
): [T, (value: T | ((val: T) => T)) => void] {
    // Get item from session storage then parse to return initial value
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.sessionStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            return initialValue
        }
    })

    // Return a wrapped version of useState's setter function that
    // persists the new value to sessionStorage
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have the same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value

            // Save state
            setStoredValue(valueToStore)

            // Save to session storage
            window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
        } catch (error) {
            console.log('Error setting session storage:', error)
        }
    }

    return [storedValue, setValue]
}

export default useSessionStorage
