import { IGiftRedemptionInfo } from '@/src/common/queries/FetchGiftRedeemFull'
import { IGiftRedemptionBasicInfo } from '@/src/common/queries/FetchGiftRedeemBasic'
import {
    BRAND_PG_PLUS,
    CARD_TO_CARD_ACTION_TYPE_CARD_LINKED,
    CARD_TO_CARD_ACTION_TYPE_EGIFT_CREATED,
    CARD_TO_CARD_ACTION_TYPE_MERCHANT_CHANGED,
    CARD_TO_CARD_ACTION_TYPE_VISA_CARD_CREATED,
    CARD_TO_CARD_UUID_TEST_PG_PLUS,
    LINK_STATUS_SUCCESS,
    MEDIUM_TYPE_CARD_TO_CARD,
    MEDIUM_TYPE_VIRTUAL,
    PG_PLUS_NAME,
} from '@/prisma/schemaConstants'
import { IGift } from '@/src/common/queries/FetchReceivedGiftDetails'
import {
    CARD_LINKED_UUID,
    DUNKIN_EGIFT_UUID,
    LOWES_EGIFT_UUID,
    PLASTIC_UUID,
    UNREDEEMED_GIFT_UUID,
    VIRTUAL_VISA_UUID,
} from '@/src/modules/account/received/constants'
import { TEgiftInfoResponse } from '@/src/common/queries/FetchEgift'
import { TPaginatedGiftWalletData } from '@/src/common/queries/FetchReceivedGifts'

export const xhrVirtualConsumerBasicInfoOk1 = {
    sender_name: 'Fake Data',
    product_name: 'Visa eGift Card',
    product_image:
        'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F14a6047677554e5585c54980e429c080',
    card_wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_limit,h_282,w_400/v1664468483/PGSite/predesigns/visa_virtual_christmas_tree_jwjyxb.png',
    recipient_message: 'I hope you enjoy your gift!',
    post_it_image: '',
}
export const xhrGiftRedemptionBasicInfoPgPlusOk1: IGiftRedemptionBasicInfo = {
    gift_description: PG_PLUS_NAME,
    gift_sender_name: 'Bill Martin',
    gift_message: 'Thank you for being so kind. Enjoy a cup of coffee on me.',
    gift_product_image_url:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1682361527/PGSite/product_images/pg_placeholder_yrxga5.png',
    gift_wrapper_image_url:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191227/PGSite/predesigns/cheers-to-you_ru5nc7.png',
    delivery_type: 'email',
    is_pg_plus: true,
}
export const xhrGiftRedemptionBasicInfoDunkinOk1: IGiftRedemptionBasicInfo = {
    gift_description: 'Dunkin',
    gift_sender_name: 'Gilbert Blythe',
    gift_message: 'Thank you for being so nice. Enjoy a cup of tea on me.',
    gift_product_image_url:
        'https://www.perfectgift.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fgift-card-granny%2Fimage%2Fupload%2Fv1683050907%2FGY%2Fmerchant_images%2FDunkin_kit5kc.png&w=828&q=75',
    gift_wrapper_image_url:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191225/PGSite/predesigns/great-job-speech-bubble_x0zpoy.png',
    delivery_type: 'email',
    is_pg_plus: false,
}
export const xhrGiftRedemptionBasicInfoEgiftOk1: IGiftRedemptionBasicInfo = {
    gift_description: 'Dunkin',
    gift_sender_name: 'Jack Sparrow',
    gift_message: 'Thank you for being so nice. Enjoy a cup of rum on me.',
    gift_product_image_url:
        'https://www.perfectgift.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fgift-card-granny%2Fimage%2Fupload%2Fv1683050907%2FGY%2Fmerchant_images%2FDunkin_kit5kc.png&w=828&q=75',
    gift_wrapper_image_url:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191226/PGSite/predesigns/congrats-you-did-it_dihvkq.png',
    delivery_type: 'email',
    is_pg_plus: false,
}
export const xhrGiftRedemptionBasicInfoPlasticOk1: IGiftRedemptionBasicInfo = {
    gift_description: 'Dunkin',
    gift_sender_name: 'Calvin Coolidge',
    gift_message: 'Thank you for being so nice. Enjoy a cup of water on me.',
    gift_product_image_url:
        'https://www.perfectgift.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fgift-card-granny%2Fimage%2Fupload%2Fv1683050907%2FGY%2Fmerchant_images%2FDunkin_kit5kc.png&w=828&q=75',
    gift_wrapper_image_url:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191226/PGSite/predesigns/colorful-hearts_ihr9dk.png',
    delivery_type: 'email',
    is_pg_plus: false,
}
export const xhrGiftRedemptionBasicInfoExchangeForEgiftBeforeRequested: IGiftRedemptionBasicInfo =
    {
        gift_description: 'Dunkin',
        gift_sender_name: 'Jack Sparrow',
        gift_message: 'Thank you for being so nice. Enjoy a cup of rum on me.',
        gift_product_image_url:
            'https://www.perfectgift.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fgift-card-granny%2Fimage%2Fupload%2Fv1683050907%2FGY%2Fmerchant_images%2FDunkin_kit5kc.png&w=828&q=75',
        gift_wrapper_image_url:
            'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191226/PGSite/predesigns/congrats-you-did-it_dihvkq.png',
        delivery_type: 'email',
        is_pg_plus: false,
    }

export const xhrGiftRedemptionFullInfoPgPlusOk1: IGiftRedemptionInfo = {
    uuid: CARD_TO_CARD_UUID_TEST_PG_PLUS,
    gift_id: 'fake-gift-id',
    denomination: 5000, // cents
    redeemable_via_card_link: true,
    redeemable_via_egift: true,
    redeemable_via_plastic: true,
    redeemable_via_zelle: true,
    brand_change_allowed: true,
    current_redemption: null,
    brand_name: null,
    brand_image: null,
    recipient_email: 'john@doe.com',
    recipient_name: 'john doe',
    recipient_phone: '1234567890',
    is_valid_egift_denom: true,
    sender_name: 'john smith',
    gift_message: 'Congratulations',
    wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191226/PGSite/predesigns/congrats-you-did-it_dihvkq.png',
    brand_slug: '',
    card_link: {
        last_four: '',
        network: '',
    },
    plastic_exchange: {
        city: '',
        state: '',
        creation_date: '',
        plastic_card_id: '',
    },
    is_pg_plus: true,
}
export const xhrGiftRedemptionFullInfoDunkinOk1: IGiftRedemptionInfo = {
    uuid: 'fake-c2c-uuid',
    gift_id: 'fake-gift-id',
    denomination: 2500, // cents
    redeemable_via_card_link: true,
    redeemable_via_egift: true,
    redeemable_via_plastic: true,
    redeemable_via_zelle: true,
    brand_change_allowed: true,
    current_redemption: CARD_TO_CARD_ACTION_TYPE_MERCHANT_CHANGED,
    brand_name: null,
    brand_image: null,
    recipient_email: 'john@doe.com',
    recipient_name: 'john doe',
    recipient_phone: '1234567890',
    is_valid_egift_denom: true,
    brand_slug: 'dunkin',
    sender_name: 'john smith',
    gift_message: 'Congratulations',
    wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191226/PGSite/predesigns/congrats-you-did-it_dihvkq.png',
    card_link: {
        last_four: '',
        network: '',
    },
    plastic_exchange: {
        city: '',
        state: '',
        creation_date: '',
        plastic_card_id: '',
    },
    is_pg_plus: false,
}
export const xhrGiftRedemptionFullInfoDunkinUnredeemedOk1: IGiftRedemptionInfo =
    {
        uuid: 'fake-c2c-uuid',
        gift_id: 'fake-gift-id',
        denomination: 2500, // cents
        redeemable_via_card_link: true,
        redeemable_via_egift: true,
        redeemable_via_plastic: true,
        redeemable_via_zelle: true,
        brand_change_allowed: true,
        current_redemption: null,
        brand_name: null,
        brand_image: null,
        is_valid_egift_denom: true,
        brand_slug: 'dunkin',
        recipient_email: 'john@doe.com',
        recipient_name: 'john doe',
        recipient_phone: '1234567890',
        sender_name: 'john smith',
        gift_message: 'Congratulations',
        wrapper_image:
            'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191226/PGSite/predesigns/congrats-you-did-it_dihvkq.png',
        card_link: {
            last_four: '',
            network: '',
        },
        plastic_exchange: {
            city: '',
            state: '',
            creation_date: '',
            plastic_card_id: '',
        },
        is_pg_plus: false,
    }
export const xhrGiftRedemptionFullInfoEgiftOk1: IGiftRedemptionInfo = {
    uuid: 'fake-c2c-uuid',
    gift_id: 'fake-gift-id',
    denomination: 2500, // cents
    redeemable_via_card_link: false,
    redeemable_via_egift: false,
    redeemable_via_plastic: false,
    redeemable_via_zelle: false,
    brand_change_allowed: false,
    current_redemption: CARD_TO_CARD_ACTION_TYPE_EGIFT_CREATED,
    brand_name: null,
    brand_image: null,
    is_valid_egift_denom: false,
    brand_slug: '',
    recipient_email: 'john@doe.com',
    recipient_name: 'john doe',
    recipient_phone: '1234567890',
    sender_name: 'john smith',
    gift_message: 'Congratulations',
    wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191226/PGSite/predesigns/congrats-you-did-it_dihvkq.png',
    card_link: {
        last_four: '',
        network: '',
    },
    plastic_exchange: {
        city: '',
        state: '',
        creation_date: '',
        plastic_card_id: '',
    },
    is_pg_plus: false,
}
export const xhrGiftRedemptionFullInfoPlasticOk1: IGiftRedemptionInfo = {
    uuid: 'fake-c2c-uuid',
    gift_id: 'fake-gift-id',
    denomination: 2500, // cents
    redeemable_via_card_link: false,
    redeemable_via_egift: false,
    redeemable_via_plastic: false,
    redeemable_via_zelle: false,
    brand_change_allowed: false,
    current_redemption: CARD_TO_CARD_ACTION_TYPE_VISA_CARD_CREATED,
    brand_name: null,
    brand_image: null,
    recipient_email: 'john@doe.com',
    recipient_name: 'john doe',
    recipient_phone: '1234567890',
    is_valid_egift_denom: false,
    brand_slug: 'visa',
    sender_name: 'john smith',
    gift_message: 'Congratulations',
    wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191226/PGSite/predesigns/congrats-you-did-it_dihvkq.png',
    card_link: {
        last_four: '',
        network: '',
    },
    plastic_exchange: {
        city: 'Pittsburgh',
        state: 'Pennsylvania',
        creation_date: '02/10/2023',
        plastic_card_id: 'fake-card-id',
    },
    is_pg_plus: false,
}
export const xhrGiftRedemptionFullInfoExchangeForEgiftBeforeRequested: IGiftRedemptionInfo =
    {
        uuid: 'fake-c2c-uuid',
        gift_id: 'fake-gift-id',
        denomination: 2500, // cents
        redeemable_via_card_link: true,
        redeemable_via_egift: true,
        redeemable_via_plastic: true,
        redeemable_via_zelle: true,
        brand_change_allowed: true,
        current_redemption: CARD_TO_CARD_ACTION_TYPE_EGIFT_CREATED,
        brand_name: 'A brand',
        brand_image:
            'https://www.perfectgift.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fgift-card-granny%2Fimage%2Fupload%2Fv1683050907%2FGY%2Fmerchant_images%2FDunkin_kit5kc.png&w=828&q=75',
        is_valid_egift_denom: true,
        brand_slug: 'brand',
        sender_name: 'john smith',
        recipient_email: 'john@doe.com',
        recipient_name: 'john doe',
        recipient_phone: '1234567890',
        gift_message: 'Congratulations',
        wrapper_image:
            'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191226/PGSite/predesigns/congrats-you-did-it_dihvkq.png',
        card_link: {
            last_four: '',
            network: '',
        },
        plastic_exchange: {
            city: '',
            state: '',
            creation_date: '',
            plastic_card_id: '',
        },
        is_pg_plus: false,
    }

export const xhrEgiftInfoPending: TEgiftInfoResponse = {
    message: 'Fetched live details',
    data: {
        status: 'pending',
        amount: 4901,
        date_created: '2023-07-18',
        description: 'With a Walmart Gift Card, you get low prices every day.',
        image: 'https://www.perfectgift.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fgift-card-granny%2Fimage%2Fupload%2Fv1683050907%2FGY%2Fmerchant_images%2FDunkin_kit5kc.png&w=828&q=75',
        instructions: 'Use it',
        merchant_id: '13fc5285-a55e-4651-aaac-c12a17fd7154',
        message: null,
        terms: 'For balance inquiry, call 1-888-537-5503.',
        brand_name: 'Remedy',
        brand_image: null,
        code: '4566306665814552',
        pin: '6918',
        barcode:
            'https://staging.one.ngcecodes.com/barcodes?sku=WALMART-E-USD&text=4566306665814552',
        date_to_process: '2023-07-18',
    },
}

export const xhrEgiftInfoFulfilled: TEgiftInfoResponse = {
    message: 'Fetched live details',
    data: {
        status: 'fulfilled',
        amount: 4901,
        date_created: '2023-07-18',
        description: 'With a Walmart Gift Card, you get low prices every day.',
        image: 'https://www.perfectgift.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fgift-card-granny%2Fimage%2Fupload%2Fv1683050907%2FGY%2Fmerchant_images%2FDunkin_kit5kc.png&w=828&q=75',
        instructions: 'Use it',
        merchant_id: '13fc5285-a55e-4651-aaac-c12a17fd7154',
        message: null,
        terms: 'For balance inquiry, call 1-888-537-5503.',
        brand_name: 'Remedy',
        brand_image: null,
        code: '4566306665814552',
        pin: '6918',
        barcode:
            'https://staging.one.ngcecodes.com/barcodes?sku=WALMART-E-USD&text=4566306665814552',
        date_to_process: '2023-07-18',
    },
}

export const xhrCreateCardLinkSessionMock = {
    session_id: '024c54ca-64e6-4d8e-8693-5615380f4184',
}

// @todo add venmo, paypal, zelle redeemed cards when available
export const xhrReceivedGifts: TPaginatedGiftWalletData = {
    offset: 0,
    limit: 12,
    count: 9,
    data: [
        {
            balance: 515,
            card_image:
                'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F70b242ba2d6641b6b5a6984bfa2611d5',
            card_network: undefined,
            card_uuid: '99f89851-b9b4-44b7-88f8-9d7f58451c2b',
            date_fulfilled: undefined,
            date_received: '7/28/2023',
            denomination: 1000,
            gift_label: 'Dunkin eGift Card',
            has_been_used: false,
            is_card_linked: false,
            is_card_to_card: true,
            is_egift_exchange: false,
            is_physical: false,
            is_virtual: false,
            last_four: undefined,
            needs_redemption: true,
            sender_name: 'Fromm Meee',
            gift_usage: 'unused',
            fulfillment_status: 'processing',
            wrapper_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/c_limit,h_282,w_400/PGSite/predesigns/visa_virtual_snowflake_fs6adi.png',
            is_pg_plus: true,
        },
        {
            balance: 515,
            card_image:
                'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F70b242ba2d6641b6b5a6984bfa2611d5',
            card_network: undefined,
            card_uuid: 'e4f97e5d-70f3-40e4-9adc-f54772dcc2a1',
            date_fulfilled: undefined,
            date_received: '7/28/2023',
            denomination: 5000,
            gift_label: 'eGift Card',
            has_been_used: false,
            is_card_linked: false,
            is_card_to_card: true,
            is_egift_exchange: false,
            is_physical: false,
            is_virtual: false,
            last_four: undefined,
            needs_redemption: false,
            sender_name: 'Fromm Meee',
            gift_usage: 'unused',
            fulfillment_status: 'processing',
            wrapper_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/c_limit,h_282,w_400/PGSite/predesigns/visa_virtual_menorah_dkoixe.png',
            is_pg_plus: true,
        },
        {
            balance: 7500,
            card_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/w_400,c_scale,q_75/GY/merchant_images/Lowe_s_wrxs9y.png',
            card_network: undefined,
            card_uuid: 'e49ae3694-bed1-4543-bad4-ec39b0c41ca8',
            date_fulfilled: undefined,
            date_received: '7/28/2023',
            denomination: 7500,
            gift_label: 'eGift Card',
            has_been_used: false,
            is_card_linked: false,
            is_card_to_card: true,
            is_egift_exchange: true,
            is_physical: false,
            is_virtual: true,
            last_four: undefined,
            needs_redemption: true,
            sender_name: 'Fromm Meee',
            gift_usage: 'unused',
            fulfillment_status: 'processing',
            wrapper_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/c_limit,h_282,w_400/v1664468483/PGSite/predesigns/visa_virtual_christmas_tree_jwjyxb.png',
            is_pg_plus: false,
        },
        {
            balance: 7500,
            card_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/w_400,c_scale,q_75/GY/merchant_images/Lowe_s_wrxs9y.png',
            card_network: undefined,
            card_uuid: 'bea391ff-3b82-43f5-91bc-d3a05b91ec69',
            date_fulfilled: undefined,
            date_received: '7/28/2023',
            denomination: 7500,
            gift_label: 'eGift Card',
            has_been_used: false,
            is_card_linked: false,
            is_card_to_card: true,
            is_egift_exchange: true,
            is_physical: false,
            is_virtual: true,
            last_four: undefined,
            needs_redemption: true,
            sender_name: 'Your Friend',
            gift_usage: 'unused',
            fulfillment_status: 'processing',
            wrapper_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191224/PGSite/predesigns/happy-holidays-2_p9kw5k.png',
            is_pg_plus: false,
        },
        {
            balance: 10000,
            card_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/v1712173813/PGSite/marketing_images/the-original-pancake-house_qumz5f.jpg',
            card_network: undefined,
            card_uuid: '2e6c422f-facb-40ec-9855-208ab817efdd',
            date_fulfilled: undefined,
            date_received: '7/28/2023',
            denomination: 10000,
            gift_label: 'eGift Card',
            has_been_used: false,
            is_card_linked: false,
            is_card_to_card: true,
            is_egift_exchange: false,
            is_physical: true,
            is_virtual: false,
            last_four: undefined,
            needs_redemption: true,
            sender_name: 'Fromm Meee',
            gift_usage: 'unused',
            fulfillment_status: 'processing',
            wrapper_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191225/PGSite/predesigns/hanukkah-time_nh3jak.png',
            is_pg_plus: false,
        },
        {
            balance: 14500,
            card_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/v1712173813/PGSite/marketing_images/the-original-pancake-house_qumz5f.jpg',
            card_network: undefined,
            card_uuid: '972b0e14-c6db-43ec-a627-c86092b4624d',
            date_fulfilled: undefined,
            date_received: '7/28/2023',
            denomination: 15000,
            gift_label: 'eGift Card',
            has_been_used: false,
            is_card_linked: false,
            is_card_to_card: true,
            is_egift_exchange: false,
            is_physical: false,
            is_virtual: false,
            last_four: undefined,
            needs_redemption: false,
            sender_name: 'Fixed Denom',
            gift_usage: 'unused',
            fulfillment_status: 'processing',
            wrapper_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191221/PGSite/predesigns/red-retro-car_raa0rs.png',
            is_pg_plus: true,
        },
        {
            balance: 845,
            card_image:
                'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F70b242ba2d6641b6b5a6984bfa2611d5',
            card_network: undefined,
            card_uuid: 'b69da78b-8d2e-4083-b800-7a5f1af3b704',
            date_fulfilled: undefined,
            date_received: '7/28/2023',
            denomination: 1000,
            gift_label: 'eGift Card',
            has_been_used: false,
            is_card_linked: true,
            is_card_to_card: true,
            is_egift_exchange: false,
            is_physical: false,
            is_virtual: false,
            last_four: '9999',
            needs_redemption: true,
            sender_name: 'Ranging Denom',
            gift_usage: 'unused',
            fulfillment_status: 'processing',
            wrapper_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/c_limit,h_282,w_400/PGSite/predesigns/visa_virtual_snowflake_fs6adi.png',
            is_pg_plus: true,
        },
        {
            balance: 845,
            card_image:
                'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F70b242ba2d6641b6b5a6984bfa2611d5',
            card_network: undefined,
            card_uuid: '794d6cc3-d8bb-4edb-bffc-b194b2ee5e12',
            date_fulfilled: undefined,
            date_received: '7/28/2023',
            denomination: 1000,
            gift_label: 'eGift Card',
            has_been_used: false,
            is_card_linked: false,
            is_card_to_card: true,
            is_egift_exchange: false,
            is_physical: false,
            is_virtual: false,
            last_four: undefined,
            needs_redemption: false,
            sender_name: 'Will be Uncalmed',
            gift_usage: 'unused',
            fulfillment_status: 'processing',
            wrapper_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/c_limit,h_282,w_400/v1664468483/PGSite/predesigns/visa_virtual_christmas_tree_jwjyxb.png',
            is_pg_plus: true,
        },
        {
            balance: 5000,
            card_image:
                'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F70b242ba2d6641b6b5a6984bfa2611d5',
            card_network: undefined,
            card_uuid: '720ee87b-be60-4de7-a66c-ac1ae92c4f64',
            date_fulfilled: undefined,
            date_received: '',
            denomination: 1000,
            gift_label: 'Dunkin eGift Card',
            has_been_used: false,
            is_card_linked: false,
            is_card_to_card: true,
            is_egift_exchange: false,
            is_physical: false,
            is_virtual: false,
            last_four: undefined,
            needs_redemption: false,
            sender_name: 'Bill Martin',
            gift_usage: 'unused',
            fulfillment_status: 'processing',
            wrapper_image:
                'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191225/PGSite/predesigns/hanukkah-time_nh3jak.png',
            is_pg_plus: true,
        },
    ],
}

// @todo add gift detail mocks for zelle, venmo, paypal, etc.
export const xhrReceivedGiftUnredeemed: IGift = {
    card_uuid: UNREDEEMED_GIFT_UUID,
    denomination: 50,
    balance: 50,
    medium_type: MEDIUM_TYPE_CARD_TO_CARD,
    brand: BRAND_PG_PLUS,
    brand_slug: 'pg-plus',
    sender_name: 'Your Friend',
    gift_message:
        'I hope you like your gift! I sent it just for you because you are cool. Enjoy my friend!',
    date_received: '12/02/23',
    date_fulfilled: '12/01/23',
    card_image:
        'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F70b242ba2d6641b6b5a6984bfa2611d5',
    wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191225/PGSite/predesigns/hanukkah-time_nh3jak.png',
    redemption_method: null,
    redemption_options: {
        redeemable_via_egift: false,
        redeemable_via_plastic: true,
        redeemable_via_zelle: true,
        redeemable_via_card_link: true,
        brand_change_allowed: true,
    },
    event_log: [
        {
            date: '12/02/23',
            description:
                'This gift was delivered via email to fakeemail@email.com.',
            time: '5:45 PM EST',
            created_at: new Date('2023-02-12 05:45:10'),
        },
        {
            date: '12/03/23',
            description: 'You opened your gift.',
            time: '8:23 PM EST',
            created_at: new Date('2023-03-12 08:23:10'),
        },
    ],
    is_pg_plus: true,
}

export const xhrReceivedGiftDunkinEGift: IGift = {
    card_uuid: DUNKIN_EGIFT_UUID,
    denomination: 65,
    balance: 0,
    brand: 'Dunkin',
    brand_slug: 'dunkin',
    gift_message: 'Have a coffee on me!',
    medium_type: MEDIUM_TYPE_CARD_TO_CARD,
    sender_name: 'Marlin Clownfish',
    date_received: '03/02/22',
    date_fulfilled: '03/04/22',
    card_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/w_400,c_scale,q_75/GY/merchant_images/dunkin_arbszt.png',
    wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_limit,h_282,w_400/PGSite/predesigns/visa_virtual_snowflake_fs6adi.png',
    redemption_method: CARD_TO_CARD_ACTION_TYPE_EGIFT_CREATED,
    gift_id: '1234-sadf-1234-asdf',
    redemption_options: {
        redeemable_via_egift: false,
        redeemable_via_plastic: false,
        redeemable_via_zelle: false,
        redeemable_via_card_link: false,
        brand_change_allowed: false,
    },
    event_log: [
        {
            date: '12/02/23',
            description:
                'This gift was delivered via email to fakeemail@email.com.',
            time: '5:45 PM EST',
            created_at: new Date('2023-02-12 05:45:15'),
        },
        {
            date: '12/03/23',
            description: 'You opened your gift.',
            time: '8:23 PM EST',
            created_at: new Date('2023-03-12 08:23:10'),
        },
        {
            date: '12/03/23',
            description: 'You made a purchase at Dunkin for $50.00.',
            time: '8:30 PM EST',
            created_at: new Date('2023-03-12 08:30:15'),
        },
        {
            date: '12/04/23',
            description: 'You made a purchase at Dunkin for $15.00.',
            time: '2:10 PM EST',
            created_at: new Date('2023-04-12 02:10:10'),
        },
    ],
    is_pg_plus: false,
}

export const xhrReceivedGiftLowesEGift: IGift = {
    card_uuid: LOWES_EGIFT_UUID,
    brand: "Lowe's",
    brand_slug: 'lowes',
    denomination: 50,
    medium_type: MEDIUM_TYPE_CARD_TO_CARD,
    sender_name: 'Jane John',
    gift_message: 'Get something good!',
    gift_id: 'fake-gift-id',
    date_received: '12/02/23',
    date_fulfilled: '12/01/23',
    card_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/w_400,c_scale,q_75/GY/merchant_images/Lowe_s_wrxs9y.png',
    wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_limit,h_282,w_400/PGSite/predesigns/visa_virtual_snowflake_fs6adi.png',
    redemption_method: CARD_TO_CARD_ACTION_TYPE_EGIFT_CREATED,
    redemption_options: {
        redeemable_via_egift: true,
        redeemable_via_plastic: true,
        redeemable_via_zelle: true,
        redeemable_via_card_link: true,
        brand_change_allowed: true,
    },
    event_log: [
        {
            date: '12/02/23',
            description:
                'This gift was delivered via email to fakeemail@email.com.',
            time: '5:45 PM EST',
            created_at: new Date('2023-02-12 05:45:15'),
        },
        {
            date: '12/03/23',
            description: 'You opened your gift.',
            time: '8:23 PM EST',
            created_at: new Date('2023-03-12 08:23:15'),
        },
        {
            date: '12/03/23',
            description: "You chose to redeem your gift as a Lowe's eGift.",
            time: '8:30 PM EST',
            created_at: new Date('2023-03-12 08:30:15'),
        },
    ],
    is_pg_plus: false,
}

export const xhrReceivedGiftVirtualVisa: IGift = {
    card_uuid: VIRTUAL_VISA_UUID,
    denomination: 200,
    balance: 140.23,
    medium_type: MEDIUM_TYPE_VIRTUAL,
    sender_name: 'Bob Johnson',
    date_received: '03/23/23',
    date_fulfilled: '03/23/23',
    card_image:
        'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F14a6047677554e5585c54980e429c080',
    wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191225/PGSite/predesigns/hanukkah-time_nh3jak.png',
    gift_message: 'Enjoy! Spend it how you like.',
    brand: 'Visa',
    brand_slug: 'visa',
    gift_id: 'fake-gift-id-1234-1234',
    event_log: [
        {
            date: '03/23/23',
            description:
                'Your gift was delivered via text message at (111)111-1111.',
            time: '5:45 PM EST',
            created_at: new Date('2023-23-03 05:45:15'),
        },
        {
            date: '03/23/23',
            description: 'You opened your gift.',
            time: '8:23 PM EST',
            created_at: new Date('2023-23-03 08:23:23'),
        },
        {
            date: '03/24/23',
            description: 'You made a purchase for $10.25.',
            time: '10:22 AM EST',
            created_at: new Date('2023-24-03 10:22:15'),
        },
        {
            date: '03/25/23',
            description: 'You made a purchase for $49.52.',
            time: '02:12 PM EST',
            created_at: new Date('2023-25-03 02:12:15'),
        },
    ],
    plastic_exchange_eligible: true,
    is_pg_plus: false,
}

export const xhrReceivedGiftPlasticFullyUsed: IGift = {
    card_uuid: PLASTIC_UUID,
    denomination: 20,
    balance: 0,
    brand: 'Visa',
    brand_slug: 'visa',
    medium_type: MEDIUM_TYPE_CARD_TO_CARD,
    sender_name: 'Nemo Clownfish',
    date_received: '03/02/22',
    date_fulfilled: '03/04/22',
    gift_message: 'Buy something good!',
    card_image:
        'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F14a6047677554e5585c54980e429c080',
    wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1666191225/PGSite/predesigns/hanukkah-time_nh3jak.png',
    redemption_method: CARD_TO_CARD_ACTION_TYPE_VISA_CARD_CREATED,
    redemption_options: {
        redeemable_via_egift: false,
        redeemable_via_plastic: false,
        redeemable_via_zelle: false,
        redeemable_via_card_link: false,
        brand_change_allowed: false,
    },
    event_log: [
        {
            date: '03/02/22',
            description:
                'Your gift was sent to 495 Mansfield Avenue, Pittsburgh, PA 15205.',
            time: '5:45 PM EST',
            created_at: new Date('2023-02-03 05:45:15'),
        },
        {
            date: '03/04/22',
            description: 'You viewed your gift balance.',
            time: '8:23 PM EST',
            created_at: new Date('2023-04-03 08:23:15'),
        },
    ],
    is_pg_plus: false,
}

export const xhrReceivedGiftLinkedFullyUsed: IGift = {
    card_uuid: CARD_LINKED_UUID,
    denomination: 200,
    balance: 0,
    brand: 'Dunkin',
    brand_slug: 'dunkin',
    medium_type: MEDIUM_TYPE_CARD_TO_CARD,
    sender_name: 'Cobra Bubbles',
    date_received: '12/02/23',
    date_fulfilled: '12/01/23',
    card_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/w_400,c_scale,q_75/GY/merchant_images/dunkin_arbszt.png',
    wrapper_image:
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_limit,h_282,w_400/v1664468483/PGSite/predesigns/visa_virtual_christmas_tree_jwjyxb.png',
    card_link: {
        gyp_gift_id: 'FAKE-DATA-1234-abcd',
        last_four: '2345',
        card_network: 'Visa',
        link_status: LINK_STATUS_SUCCESS,
        brand_name: 'Dunkin',
    },
    redemption_method: CARD_TO_CARD_ACTION_TYPE_CARD_LINKED,
    redemption_options: {
        redeemable_via_egift: false,
        redeemable_via_plastic: false,
        redeemable_via_zelle: false,
        redeemable_via_card_link: false,
        brand_change_allowed: false,
    },
    event_log: [
        {
            date: '12/02/23',
            description:
                'Your gift was delivered via text message at (111)111-1111.',
            time: '5:45 PM EST',
            created_at: new Date('2023-02-12 05:45:15'),
        },
        {
            date: '12/02/23',
            description: 'You opened your gift.',
            time: '8:23 PM EST',
            created_at: new Date('2023-02-12 08:23:15'),
        },
        {
            date: '12/02/23',
            description:
                'You chose to connect your gift to your Visa ending 1234.',
            time: '8:25 PM EST',
            created_at: new Date('2023-02-12 08:25:15'),
        },
        {
            date: '12/04/23',
            description: 'You made a purchase at Home Depot for $90.00.',
            time: '10:22 AM EST',
            created_at: new Date('2023-04-12 10:22:15'),
        },
        {
            date: '12/04/23',
            description:
                'PerfectGift.com credited your Visa ending in 1234 with $90.00.',
            time: '10:23 AM EST',
            created_at: new Date('2023-04-12 10:23:10'),
        },
        {
            date: '12/05/23',
            description: 'You made a purchase for $110.00.',
            time: '02:12 PM EST',
            created_at: new Date('2023-05-12 02:12:10'),
        },
        {
            date: '12/05/23',
            description:
                'PerfectGift.com credited your Visa ending in 1234 with $110.00.',
            time: '02:13 PM EST',
            created_at: new Date('2023-05-12 02:13:10'),
        },
    ],
    is_pg_plus: false,
}
