import { styled } from '@/src/stitches.config'
import { DialogContent } from '@/src/common/components/elements/Modals/Modal'

export const ModalComponent = styled(DialogContent, {
    '& p': {
        fontSize: '1rem',
        lh: '1.65rem',
    },
    variants: {
        variant: {
            iframe: {
                height: 'fit-content',
                padding: 0,
            },
        },
    },
})

export const ModalContent = styled('div', {
    '.gradient-text': {
        linearGradient: `to right, $primary, $secondary`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    '.bold': {
        fontWeight: 'bold',
    },
    variants: {
        variant: {
            default: {
                pt: '3%',
                px: '2%',
                mb: 0,
                display: 'flex',
                ai: 'center',
                fd: 'column',
            },
            iframe: {
                position: 'relative',
                px: 0,
                pt: 0,
                height: 'fit-content',
                '& iframe': {
                    border: 'none',
                },
            },
        },
        linkColor: {
            primary: {
                '& a': {
                    color: '$primary',
                    '&:hover': {
                        color: '$secondaryDark',
                    },
                },
            },
            secondary: {
                '& a': {
                    color: '$secondary',
                    '&:hover': {
                        color: '$secondaryDark',
                    },
                },
            },
        },
    },
    defaultVariants: {
        variant: 'default',
        linkColor: 'primary',
    },
})

export const ModalIconButton = styled('div', {
    py: '5px',
    '& svg': {
        color: '$secondary',
        fontSize: '30px',
    },
    '#close': {
        position: 'absolute',
        p: 1,
        top: 8,
        right: 8,
        borderRadius: '100%',
        height: 27,
        width: 27,
        color: '$dark',
        '&:hover': { backgroundColor: '$light' },
    },
})

// can fix this !important when DefaultModal is refactored
export const ModalHeader = styled('p', {
    width: '100%',
    fontSize: '1.45rem !important',
    fontWeight: 'bold',
    lineHeight: '1.5 !important',
    mt: 0,
    mb: 10,
    '@media (max-width: 455px)': {
        mt: 10,
    },
})

export const ModalBodyWrapper = styled('div', {
    maxWidth: '100%',
    '& img': {
        maxWidth: '100%',
        height: 'auto',
        margin: 'auto',
        display: 'block',
    },
})

export const ModalButtonsWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 20,
    '& button': {
        outline: 'unset',
    },
    variants: {
        align: {
            wrap: {
                flexDirection: 'row-reverse',
                flexWrap: 'wrap',
            },
        },
        marginTop: {
            none: {
                mt: 0,
            },
        },
        gap: {
            '5px': {
                gap: 5,
            },
        },
    },
})

export const ModalButtonsFullSizeWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 20,
    variants: {
        align: {
            wrap: {
                flexDirection: 'row-reverse',
                flexWrap: 'wrap',
            },
        },
    },
})
