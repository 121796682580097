import { styled } from '@/src/stitches.config'

export const AlertWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    br: 4,
    '& ul': {
        py: 0,
        pl: 20,
        '& li': {
            fontSize: '.85rem',
            lineHeight: 1.2,
        },
    },
    variants: {
        variant: {
            error: {
                color: '$error',
                backgroundColor: '$errorLight',
                '& p, ul': {
                    color: '$error',
                },
            },
            warning: {
                color: '$dark',
                backgroundColor: '$accentYellowLight',
                '& ul': {
                    color: '$warningBrown',
                },
                '& svg': {
                    color: '$warning',
                },
            },
            success: {
                color: '$success',
                backgroundColor: '$successGreenLight',
                '& p, ul': {
                    color: '$success',
                },
            },
            info: {
                color: '$infoDarkText',
                backgroundColor: '$info',
                '& p, ul': {
                    fontSize: '$2',
                    color: '$infoDarkText',
                },
                '& ul': {
                    margin: '0',
                },
                '& svg': {
                    color: '$infoIcon',
                    alignSelf: 'start',
                    width: '20px',
                    height: '20px',
                },
            },
        },
        size: {
            sm: {
                padding: '0px 15px',
                gap: 10,
                svg: {
                    display: 'block',
                    fontSize: 22,
                },
            },
            md: {
                padding: 15,
                gap: 18,
                svg: {
                    display: 'block',
                    fontSize: 30,
                    '@sm': {
                        fontSize: 50,
                    },
                },
            },
        },
        addPaddingY: {
            true: {
                py: 10,
            },
            false: {},
        },
    },
    defaultVariants: {
        size: 'md',
    },
})

export const AlertTextWrapper = styled('div', {
    display: 'flex',
    fd: 'column',
    gap: 20,
})

export const AlertText = styled('div', {
    fontSize: 15,
    lineHeight: 1.25,
    fontWeight: '500 !important',
    margin: '0px !important',
    '& a': {
        color: '$secondary',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    '.remaining-load': {
        display: 'block',
        marginTop: 20,
    },
})
