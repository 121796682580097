export const PG_CART_SESSION_UUID: string = 'pgCartSessionUuid'
export const PG_CART_STATE_UUID: string = 'pgCartStateUuid'
export const IS_CUSTOM_LEAD_FORM: string = 'isCustomLeadForm'
export const RISKIFIED_SESSION_ID: string = 'riskifiedSessionId'

export const WL_CART_SESSION_UUID: string = 'whiteLabelCartSessionUuid'
export const WL_CART_STATE_UUID: string = 'whiteLabelCartStateUuid'

export const PROMO_CODE: string = 'promo_code'

// Manufactured spend buyers get a cookie set and passed to Order
export const BRICKYARD_MS_BUYER: string = 'brickyard_ms'
