import { useQuery } from 'react-query'
import getCart from '../queries/GetCartQuery'
import { useCookies } from 'react-cookie'
import {
    PG_CART_SESSION_UUID,
    PG_CART_STATE_UUID,
    WL_CART_SESSION_UUID,
    WL_CART_STATE_UUID,
} from '@/config/cookieNames'
import { PARTNER_DATA_COOKIE, PARTNER_SLUG } from '@/src/common/constants'
import { useRouter } from 'next/router'
import { determineIfInValidPartnerFlow } from '@/src/modules/partners/utilities/utilities'

const useGetCartManually = () => {
    const [cookies, setCookie] = useCookies([
        PG_CART_SESSION_UUID,
        PARTNER_DATA_COOKIE,
        PARTNER_SLUG,
        WL_CART_SESSION_UUID,
        WL_CART_STATE_UUID,
    ])

    const router = useRouter()
    const { useWLCart } = determineIfInValidPartnerFlow(router, cookies)

    const cartSessionUuid =
        cookies[useWLCart ? WL_CART_SESSION_UUID : PG_CART_SESSION_UUID]

    const queryKey = ['cartSessionUuid', cartSessionUuid]
    const { refetch, data, error, isLoading, isSuccess, isError } = useQuery(
        queryKey,
        getCart,
        {
            onSuccess: (cartResponse: {
                cart_session_uuid: string
                cart_state_uuid: string
            }) => {
                // cartResponse could be empty if no cart exists
                if (cartResponse?.cart_session_uuid) {
                    setCookie(
                        useWLCart ? WL_CART_SESSION_UUID : PG_CART_SESSION_UUID,
                        cartResponse.cart_session_uuid,
                        { path: '/', domain: window.location.hostname }
                    )
                    setCookie(
                        useWLCart ? WL_CART_STATE_UUID : PG_CART_STATE_UUID,
                        cartResponse.cart_state_uuid,
                        {
                            path: '/',
                            domain: window.location.hostname,
                        }
                    )
                }
            },
        }
    )

    return { refetch, data, error, isLoading, isSuccess, isError }
}

export default useGetCartManually
