export const GIFTS_RECEIVED_PAGE: string = '/account/gifts/received'
export const ACCOUNT_PAGE: string = '/account'
export const LOGIN_PAGE: string = '/login'
export const ORDERS_PAGE: string = '/account/orders'
export const MANAGE_ACCOUNT: string = '/account/manage-account'
export const REFERRALS_PAGE: string = '/account/referrals'
export const SELF_CREDIT_PAGE: string = '/account/credit'
export const SMB_APPLICATION_PAGE: string = '/small-business-signup'

export const UNREDEEMED_GIFT_UUID: string = 'unredeemed-gift'
export const LOWES_EGIFT_UUID: string = 'lowes-egift'
export const PLASTIC_UUID: string = 'plastic'
export const CARD_LINKED_UUID: string = 'card-linked'
export const VIRTUAL_VISA_UUID: string = 'virtual-visa'
export const DUNKIN_EGIFT_UUID: string = 'dunkin-egift'

export const REDEEM_STATE_SUCCESS: string = 'success'
export const REDEEM_STATE_ERROR: string = 'error'
export const REDEEM_STATE_PENDING: string = 'pending'
