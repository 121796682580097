import { useCookies } from 'react-cookie'
import {
    PG_CART_SESSION_UUID,
    PG_CART_STATE_UUID,
    WL_CART_SESSION_UUID,
    WL_CART_STATE_UUID,
} from '@/config/cookieNames'
import { useCallback, useEffect } from 'react'
import { PARTNER_DATA_COOKIE, PARTNER_SLUG } from '@/src/common/constants'

export const useSyncCookies = () => {
    const [cookies, , removeCookie] = useCookies([
        PG_CART_SESSION_UUID,
        WL_CART_SESSION_UUID,
        PG_CART_STATE_UUID,
        WL_CART_STATE_UUID,
        PARTNER_DATA_COOKIE,
        PARTNER_SLUG,
    ])

    // Removing the cart cookies effectively clears the cart within the client
    const removeMainCartCookies = useCallback(() => {
        removeCookie(PG_CART_SESSION_UUID, {
            path: '/',
            domain: window.location.hostname,
        })
        removeCookie(PG_CART_STATE_UUID, {
            path: '/',
            domain: window.location.hostname,
        })
    }, [removeCookie])

    // Function to remove cookie and sync across tabs
    const clearMainCart = useCallback(() => {
        removeMainCartCookies()
        localStorage.setItem('clear_main_cart', Date.now().toString()) // Sync deletion
    }, [removeMainCartCookies])

    const removeWhiteLabelCart = useCallback(() => {
        removeCookie(WL_CART_SESSION_UUID, {
            path: '/',
            domain: window.location.hostname,
        })
        removeCookie(WL_CART_STATE_UUID, {
            path: '/',
            domain: window.location.hostname,
        })
    }, [removeCookie])

    const clearWhiteLabelCart = useCallback(() => {
        removeWhiteLabelCart()
        localStorage.setItem('clear_wl_cart', Date.now().toString())
    }, [removeWhiteLabelCart])

    // Removes stored white label partner data
    const removePartnerData = useCallback(() => {
        if (!!cookies[PARTNER_DATA_COOKIE]) {
            removeCookie(PARTNER_DATA_COOKIE, {
                path: '/',
                domain: window.location.hostname,
                sameSite: 'strict',
            })
        }
    }, [cookies, removeCookie])

    // Function to remove white label cookie and sync across tabs
    const clearPartnerData = useCallback(() => {
        removePartnerData()
        localStorage.setItem('clear_partner_data', Date.now().toString()) // Sync deletion
    }, [removePartnerData])

    const removePartnerSlug = useCallback(() => {
        if (!!cookies[PARTNER_SLUG]) {
            removeCookie(PARTNER_SLUG, {
                path: '/',
                domain: window.location.hostname,
                sameSite: 'strict',
            })
        }
    }, [cookies, removeCookie])

    const clearPartnerSlug = useCallback(() => {
        removePartnerSlug()
        localStorage.setItem('clear_partner_slug', Date.now().toString())
    }, [removePartnerSlug])

    // Listen for cookie removal events from other tabs
    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'clear_main_cart') {
                removeMainCartCookies()
            }

            if (event.key === 'clear_wl_cart') {
                removeWhiteLabelCart()
            }

            if (event.key === 'clear_partner_data') {
                removePartnerData()
            }

            if (event.key === 'clear_partner_slug') {
                removePartnerSlug()
            }
        }

        window.addEventListener('storage', handleStorageChange)
        return () => window.removeEventListener('storage', handleStorageChange)
    }, [
        removeMainCartCookies,
        removePartnerData,
        removePartnerSlug,
        removeWhiteLabelCart,
    ])

    return {
        clearMainCart,
        clearWhiteLabelCart,
        clearPartnerData,
        clearPartnerSlug,
    }
}
