import { NextRouter } from 'next/router'
import { PARTNER_DATA_COOKIE, PARTNER_SLUG } from '@/src/common/constants'

export function determineIfInValidPartnerFlow(
    router: NextRouter,
    cookies: { [p: string]: any }
): {
    hasPartner: boolean
    isInvalidPathForPartner: boolean
    useWLCart: boolean
} {
    const urlPath = router.asPath

    const hasPartner = !!cookies[PARTNER_DATA_COOKIE] || !!cookies[PARTNER_SLUG]
    const isInPartnerFlow: boolean = urlPath.includes('/partner/')

    // allow users through purchase redirects
    const isVenmoRedirect: boolean = urlPath.includes('/checkout/venmo-redirect')
    const isAmazonPayRedirect: boolean = urlPath.includes('/checkout/amazon-pay-redirect')

    const isInvalidPathForPartner: boolean =
        !isInPartnerFlow && !isVenmoRedirect && !isAmazonPayRedirect

    const useWLCart = hasPartner && !isInvalidPathForPartner

    return { hasPartner, isInvalidPathForPartner, useWLCart }
}
